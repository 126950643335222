<template lang="pug">
v-container(grid-list-md fill-height)
  template(v-if="item")
    .instruction
      h1.section-title {{ item.name }}
      router-link.primary--text(to="/faq/instructions") Перейти ко всем инструкциям
      .instructions__instruction(v-html="item.body")
      v-btn.ml-0(
        color="primary"
        depressed
        @click="getInstructionPdf"
        :loading="loadingDownload"
      ) Выгрузить в формате pdf
  template(v-else)
    the-loading
</template>

<script>
import MainService from '@/services/main';

export default {
  props: {
    id: { type: [Number, String], default: null },
  },
  data() {
    return {
      item: null,
      loadingDownload: false,
    };
  },
  watch: {
    id() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.item = null;
      if (this.id) { this.loadInstruction(); }
    },
    loadInstruction() {
      this.$rHandler(
        MainService.getInstructionDetail(this.id),
        (response) => { this.item = response.data.data; },
      );
    },
    getInstructionPdf() {
      this.loadingDownload = true;
      this.$rHandler(
        MainService.getInstructionPdf(this.id),
        (response) => {
          window.open(response.data.data.url);
        },
      ).finally(() => { this.loadingDownload = false; });
    },
  },
};
</script>
