<template lang="pug">
v-container(grid-list-md)
  smart-dialog(
    v-model="showInstruction"
    title="Инструкция"
    fullscreen
  )
    the-instruction(v-if="currentIdInstruction" :id="currentIdInstruction")

  the-loading(v-if="!hasInstructions")
  v-card(v-else flat)
    v-card-text
      h1.section-title Прежде чем продолжить ознакомьтесь с инструкциями
      v-checkbox(
        color="primary"
        v-model="selected"
        v-for="(instruction, idx) in instructionsNew"
        :value="instruction.id"
        :key="idx"
      )
        template(v-slot:label)
          div
            span Инструкция
            a.primary--text(
              @click="openInstruction(instruction.id)"
            )  «{{ instruction.name }}»
      v-checkbox(
        color="primary"
        v-model="selected"
        v-for="(instruction, idx) in instructionsUpdated"
        :value="instruction.id"
        :key="idx"
      )
        template(v-slot:label)
          div
            span Инструкция изменена
            router-link.primary--text(
              :to="{ name: 'faq-instruction-detail', params: { id: instruction.id }}"
            )  «{{ instruction.name }}»

    v-card-text.actions
      slot(name="actions")
        v-btn.ml-0(
          color="primary"
          :disabled="!isAllMarked"
          @click="markInstructions"
          :loading="loading"
          depressed
        ) С инструкцией ознакомлен
</template>

<script>
import SmartDialog from '@/components/SmartDialog';
import TheInstruction from '@/modules/StartPage/components/TheInstruction';

import MainService from '@/services/main';
import { mapGetters } from 'vuex';


export default {
  components: {
    SmartDialog,
    TheInstruction,
  },
  data() {
    return {
      selected: [],
      instructionsNew: [],
      instructionsUpdated: [],
      loading: false,
      hasInstructions: false,
      showInstruction: false,
      currentIdInstruction: null,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'getUserRole',
    }),
    isAllMarked() {
      return this.selected.length === (this.instructionsNew.length + this.instructionsUpdated.length);
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch('LOCK_NAVIGATION');
      this.$rHandler(
        MainService.getRequiredInstructions(),
        (response) => {
          // Если нет инструкций
          if (!response.data.data.status) {
            return this.goNextPage();
          }
          this.hasInstructions = true;
          this.instructionsNew = response.data.data.new;
          this.instructionsUpdated = response.data.data.updated;
          return response;
        },
      );
    },
    markInstructions() {
      this.loading = true;
      this.$rHandler(
        MainService.markInstructions(this.selected),
        () => {
          this.goNextPage();
        },
      ).finally(() => { this.loading = false; });
    },
    goNextPage() {
      if (this.userRole === 'certificate') {
        this.$router.push({ name: 'certificate-policy-confirm' });
      } else {
        this.$store.dispatch('UNLOCK_NAVIGATION');
        this.$router.push(this.$route.query.redirect || '/desktop');
      }
    },
    openInstruction(id) {
      this.showInstruction = true;
      this.currentIdInstruction = id;
    },
  },
};
</script>
